@media all and (min-width: 480px) {
  .Eula {
    padding: 60px 0;
  }

  .Eula form {
    margin: 0 auto;
/*    max-width: 320px; */
  }
}
